@import 'src/common/sass/common';

.PortfolioSection{
  background-color: #F6F7FA;
  padding-bottom: 100px;
  background: url("../../../src/asset/back.jpg");
  background-size: cover;
  background-attachment: fixed;
  >div:last-child{
    width: 50%;
    margin: auto;
  }
  .ant-btn{
  }
  @media (min-width: 200px) and (max-width: 768px){
    background-size: cover;
  }
}