@import './src/common/sass/common';
.HeaderArea{
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.87);
  .HeaderContentArea{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .HeaderLogoBox {
      height: 100%;
      margin-left: 40px;
      img {
        content: url('../../asset/Header/HeaderLogo.png');
        height: 100%;
        float: left;
      }
    }
    .HeaderMenuBox{
      margin-right: 20px;
      width: 700px;
      height: 100%;
      //border: 1px solid red;
      ul{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: baseline;
        li{
          @include menuTextStyle;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: color 0.3s ease;
          &:hover{
            color: #dc0b0b;
            border-bottom: 2px solid #dc0b0b;
            cursor: pointer;
          }
        }
      }
    }
    .HeaderMenuBoxMobile{
      margin-right: 15px;
      display: none;
      width: 50px;
      height: 100%;
      text-shadow: 0.1rem 0.1rem 0.1rem #333;
      color: white;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  }
}
.fixeHead{
  background-color: white;
  box-shadow: 0px 0px 2px 1px gray;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  .HeaderContentArea{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .HeaderLogoBox {
      height: 100%;
      margin-left: 40px;
      img {
        content: url('../../asset/Header/HeaderLogo.png');
        height: 100%;
        float: left;
      }
    }
    .HeaderMenuBox{
      margin-right: 20px;
      width: 700px;
      height: 100%;
      ul{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        li{
          @include menuTextStyle;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: color 0.3s ease;
          &:hover{
            color: #dc0b0b;
            border-bottom: 2px solid #dc0b0b;
            cursor: pointer;
          }
        }
      }
    }
    .HeaderMenuBoxMobile{
      display: none;
      margin-right: 15px;

      height: 100%;
      width: 50px;
      color: $titleFontColor;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  }
}
.mobileMenuArea{
  display: none;
}
@media (min-width:200px) and (max-width:1280px){
  .HeaderArea{
    height: 70px;
    .HeaderContentArea .HeaderLogoBox{
      margin-left: 0px;
    }
  }
  .fixeHead{
    height: 80px;
    .HeaderContentArea .HeaderLogoBox{
      margin-left: 0px;
    }
  }
  .HeaderMenuBox{display: none}
  .HeaderMenuBoxMobile{
    display: flex !important;
    cursor: pointer;
  }
  .mobileMenuArea{
    transition: all 0.6s ease;
    width: 250px;
    height: 100vh;
    background: white;
    box-shadow: 1px 1px 3px 1px rgba(161, 161, 161, 0.5);
    @include positionSet(fixed, 0, none, none, none, none);
    @include flexSet(column, center, center);
    div{
      width: 100%;
      height: 10%;
      @include flexCenter;
      font-size: 15px;
      transition: all 0.4s ease;
      &:hover{
        background: $menuHoverColor;
        color: white;
      }
    }
    span{
      @include positionSet(absolute, 3%, 5%, none, none, none);
      font-size: 15px;
      color: $titleFontLightColor;
      cursor: pointer;
    }
  }
}