@import './src/common/sass/common';
.PortfolioCard{
  @include flexSet(row, none, center);
  width: 1200px;
  height: 300px;
  margin: auto;
  margin-bottom: 80px;
  box-shadow: 1px 1px 2px 1px rgba(211, 211, 211, 0.51);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #e1e1e1;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  padding: 20px;
  >div:first-child{
    width: 500px;
    height: 100%;
    //border-right: 2px solid #e8e8e8;
    img{
      width: 100%;
      height: 300px;
      object-fit: contain;
      margin: 0;
    }
    li button{
      background-color: #212121 !important;
      //background-color: transparent !important;
      height: 7px;
      margin-top: 20px;
      box-shadow: 1px 1px 2px 1px rgb(0, 0, 0, 0.2);
      border-radius: 10px;
      &::before{
        display: none;
      }
    }
  }
  .portfolioDetailArea{
    width: 618px;
    height: calc(100% - 60px);
    position: absolute;
    display: none;
    right: 0;
    padding: 30px 40px;
    background: white;
    z-index: 10;
    border-left: 2px solid #e8e8e8;
    @include portDetailTextStyle;
    overflow-y: scroll;
    white-space: pre-wrap;
    word-wrap: break-word;
    &::-webkit-scrollbar{
      display: none;
    }
    h3{
      position: absolute;
      right: 10px;
      bottom: 9px;
      @include portSubStyle;
      cursor: pointer;
      padding: 3px 7px;
      &:hover{
        color: black;
      }
    }
  }
  >div:last-child{
    width: 700px;
    height: 100%;
    margin-left: 35px;
    position: relative;
    >h1{
      @include portTitleStyle;
      margin-top: 12px;
    }
    >h5{
      @include portSubStyle;
      margin-top: 5px;
    }
    >p{
      display: inline-block;
      @include portTextStyle;
      margin-top: 15px;
      height: 150px;
      width: 500px;
      @include textHidden;

    }
    >div{
      margin-top: 10px;
      width: fit-content;
      height: 25px;
      @include flexSet(row, flex-start, center);
      flex-wrap: wrap;
      span{

      }
    }
    >img{
      display: inline-block;
      width: 150px;
      height: 150px;
      object-fit: contain;
      @include positionSet(absolute, 50%, 15px, none, none, translateY(-50%));
    }
    >button{
      background-color: transparent;
      padding: 3px 7px;
      border: none;
      position: absolute;
      right: 10px;
      bottom: 7px;
      @include portSubStyle;
      cursor: pointer;
      display: none;
      &:hover{
        color: black;
      }
    }
  }
  @media (min-width:768px) and (max-width:1280px) {
    width: 760px;
    height: 200px;
    >div:first-child{
      width: 300px;
      img{
        height: 200px;
      }
    }
    .portfolioDetailArea{
      width: 420px;
      height: calc(100% - 60px);
      padding: 30px 20px;
      background: white;
      z-index: 10;
      h3{
        position: absolute;
        right: 10px;
        bottom: 9px;
        @include portSubStyle;
        cursor: pointer;
        padding: 3px 7px;
        &:hover{
          color: black;
        }
      }
    }
    >div:last-child{
      >h1{
      }
      >h5{
      }
      >p{
        height: 100px;
        width: 300px;
        margin-top: 5px;
      }
      >div{
        margin-top: 2px;
        span{
        }
      }
      >img{
        width: 80px;
        height: 80px;
      }
      >button{
      }
    }
  }
  @media (min-width:200px) and (max-width:768px){
    flex-direction: column;
    width: 300px;
    height: fit-content;
    margin-bottom: 50px;
    >div:first-child{
      width: 300px;
      margin-bottom: 20px;
      img{
        height: 200px;
      }
      li button{
        height: 4px;
        margin-top: 20px;
        box-shadow: 1px 1px 2px 1px rgb(0, 0, 0, 0.2);
        border-radius: 10px;
        &::before{
          display: none;
        }
      }
    }
    .portfolioDetailArea{
      width: 260px;
      z-index: 100;
      h3{
        &:hover{
        }
      }
    }
    >div:last-child{
      width: 300px;
      height: fit-content;
      margin-left: 20px;

      >h1{
        width: 280px;
      }
      >h5{
        width: 280px;
      }
      >p{
        width: 280px;
        height: 100px;
      }
      >div{
        width: 280px;
        span{
        }
      }
      >img{
        display: none;
      }

    }
  }
}