//COLOR LIST
$white : #f9f9f9;
$titleFontColor : #333;
$titleFontLightColor : #868686;
$contentFontColor : #525252;
$brandColor : #ff0000;
$hiddenSectionColor : #f8f8f8;
$footerColor : #001834;
$menuHoverColor : #d22547;
$textUnderLine : rgba(61, 88, 184, 0.7);
//COLOR LIST END

//SASS MIXIN
    //text... 생략
    @mixin textHidden(){
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        @media (min-width:768px) and (max-width:1280px) {
            -webkit-line-clamp: 5;
        }
        @media (min-width:200px) and (max-width:768px){
            -webkit-line-clamp: 5;
        }
    }
    //position
    @mixin flexCenter(){
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    @mixin flexSet($fd, $jc, $ai){
        display: flex;
        flex-direction: $fd;
        justify-content: $jc;
        align-items: $ai;
    }
    @mixin positionSet($po, $top, $right, $bottom, $left, $trs){
        position: $po;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        transform: $trs;
    }
    @mixin backgroundSet($size, $po){
        background-size: $size;
        background-position: $po;
        background-repeat: no-repeat;
    }
    //style
    @mixin menuTextStyle(){
        font-family: 'TheJamsil5Bold';
        font-weight: bold;
        font-size: 15px;
        color: #333;
    }
    @mixin sectionTitleStyle(){
        font-size: 40px;
        font-family: "GmarketSans";
        font-weight: 700;
        color: $titleFontColor;
        //text-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25);
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        padding-bottom: 30px;
        @media (min-width:768px) and (max-width:1280px) {
            margin-left: 40px;
            font-size: 30px;
        }
        @media (min-width:200px) and (max-width:768px){
            margin-left: 20px;
            font-size: 20px;
            padding-bottom: 20px;
        }
    }
    @mixin sectionSubTitleStyle(){
        font-size: 16px;
        font-weight: 100;
        color: $contentFontColor;
        line-height: 1.5;
        word-break: keep-all;
        @media (min-width:768px) and (max-width:1280px) {
            margin-left: 40px;
            font-size: 14px;
        }
        @media (min-width:200px) and (max-width:768px){
            margin-left: 20px;
            font-size: 10px;
        }
    }
    @mixin mainTitleStyle(){
        font-family: "GmarketSans";
        font-weight: bold;
        font-size: 48px;
        color: $white;
        text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
    }
    @mixin contentTitleStyle(){
        font-family: "GmarketSans";
        font-size: 48px;
        color: #333;
        font-weight: bold;
        @media (min-width:768px) and (max-width:1280px) {
            font-size: 30px;
        }
        @media (min-width:200px) and (max-width:768px){
            font-size: 18px;
        }
    }
    @mixin contentSubStyle(){
        font-size: 18px;
        color: $contentFontColor;
        white-space: pre-wrap;
        line-height: 1.5;
        @media (min-width:768px) and (max-width:1280px) {
            font-size: 15px;
        }
        @media (min-width:200px) and (max-width:768px){
            font-size: 12px;
            width: 300px;
            word-break: keep-all;
        }
    }
    @mixin techTitleStyle(){
        font-family: 'NanumSquareNeo-Variable', monospace, sans-serif;
        font-size: 27px;
        font-weight: 300;
        color: #222;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 30px;
        word-break: break-word;
        @media (min-width:768px) and (max-width:1280px) {
            font-size: 20px;
            margin-bottom: 20px;
        }
        @media (min-width:200px) and (max-width:768px){
            color: #333;
            font-size: 14px;
            margin-bottom: 15px;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        }
    }
    @mixin techTextStyle(){
        font-size: 14px;
        color: #888888;
        line-height: 1.5;
        word-break: keep-all;
        @media (min-width:768px) and (max-width:1280px) {
            font-size: 12px;
        }
        @media (min-width:200px) and (max-width:768px){
            font-size: 10px;
        }
    }
    @mixin hiddenBtnStyle(){
        font-size: 12px;
        font-weight: bold;
        background-color: transparent;
        color: #777777;
        border: none;
        outline: none;
        cursor: pointer;
        transition: color 0.3s ease;
        &:hover{
            color: #000;
        }
        @media (min-width:768px) and (max-width:1280px) {
            font-size: 10px;
        }
        @media (min-width:200px) and (max-width:768px){
            font-size: 10px;
        }
    }
    @mixin portTitleStyle(){
        font-family: 'Nanum Gothic', sans-serif;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
        @media (min-width:768px) and (max-width:1280px) {
            font-size: 15px;
            margin-bottom: 5px;
        }
        @media (min-width:200px) and (max-width:768px){
            font-size: 13px;
        }
    }
    @mixin portSubStyle(){
        font-size: 15px;
        color: #adadad;
        @media (min-width:768px) and (max-width:1280px) {
            font-size: 8px;
        }
        @media (min-width:200px) and (max-width:768px){
            font-size: 8px;
        }
    }
    @mixin portTextStyle(){
        font-family: 'Nanum Gothic', sans-serif;
        font-size: 15px;
        color: #333333;
        white-space: pre-wrap;
        line-height: 1.7;
        @media (min-width:768px) and (max-width:1280px) {
            font-size: 12px;
        }
        @media (min-width:200px) and (max-width:768px){
            font-size: 10px;
        }
    }
    @mixin portDetailTextStyle(){
        font-family: 'Nanum Gothic', sans-serif;
        font-size: 15px;
        color: #333333;
        @media (min-width:768px) and (max-width:1280px) {
            font-size: 14px;
        }
        @media (min-width:200px) and (max-width:768px){
            font-size: 12px;
        }
    }
//SASS MIX END

//
