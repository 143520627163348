.FooterArea{
  width: 100vw;
  height: 200px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-around;
  >div:first-child{
    width: 800px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    div{
      text-align: left;
      color: #4f4f4f;
      p{
        font-size: 10px;
        margin-bottom: 10px;
      }
      img{
        content: url("../../asset/Header/HeaderLogo.png");
        width: 200px;
        object-fit: contain;
        display: flex;
      }
    }
  }
  >div:last-child{
    width: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-select{
      width: 112px;
    }
    .cascader{
      .ant-select-selector{
        background: transparent;
        width: 112px;
      }
      .ant-select-selection-placeholder{
        font-size: 10px;
        text-align: center;
        color: #999;
      }
      .ant-select-arrow{
        right: 15px;
      }
    }
  }
  @media (min-width:200px) and (max-width:768px){
    height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >div:first-child{
      flex-direction: column;
      padding-top: 20px;
      width: 300px;
      p{
        word-break: keep-all;
      }
    }
    >div:last-child{
    }
  }
}