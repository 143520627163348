@import "src/common/sass/common";

.TechnologyCard{
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 60px auto;
  .techImg {
    width: 500px;
    height: 300px;
    img {
      width: 100%;
      height:100%;
      border-radius: 20px;
      box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.45);
    }
  }
  .techText {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    white-space: pre-wrap;
    .techMain {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      p{
        margin: 0;
        @include techTextStyle;
      }
    }
  }
  button{
    @include hiddenBtnStyle;
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1280px){
    width: 85%;
    justify-content: space-around;
    .techImg{
      width: 300px;
      height: 200px;
    }
  }
  @media (min-width: 200px) and (max-width: 768px){
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 35px auto;
    .techImg{
      width: 300px;
      height: 200px;
      margin-bottom: 20px;
    }
    .techText{
      width: 90%;
      .techMain{
        align-items: center;
        p{
          margin-bottom: 10px;
        }
      }
    }
  }
}

.TechnologyDetail{
  background-color: #f3f3f3;
  box-shadow: 0px -2px 9px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  .detail {
    margin: 50px auto;
    display: flex;
    //flex-direction: row;
    flex-direction: column;
    width: 1280px;
    justify-content: space-around;
    .detailImg {
      order: 3;
      width: 1200px;
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        cursor: pointer;
        height: 100%;
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
        object-fit: contain;
      }
    }
    .detailText {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      margin-bottom: 50px;
        h1{
          @include techTitleStyle;
          font-size: 18px;
          text-shadow: none;
        }
        p{
          @include sectionSubTitleStyle;
        }
    }
    @media (min-width: 768px) and (max-width: 1280px){
      width: 80%;
      .detailText{
        p{
          margin: 0;
        }
      }
      .detailImg{
        width: 700px;
        height: 400px;
        margin: auto;
        img{
          height: 100%;
        }
      }

    }
    @media (min-width: 200px) and (max-width: 768px){
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin: 20px 0;
      .detailImg{
        width: 300px;
        height: 200px;
        img{
          width: 300px;
          height: 200px;
        }
      }
      .detailText{
        width: 300px;
        margin-bottom: 20px;
        h1{
          margin: 15px;
        }
      }
    }
  }
  button{
    @include hiddenBtnStyle;
    width: 1200px;
    text-align: right;
    margin: 30px auto;
    @media (min-width: 768px) and (max-width: 1280px){
      width: 80%;
    }
    @media (min-width: 200px) and (max-width: 768px){
      width: 80%;
      margin: 15px auto;
    }
  }
}